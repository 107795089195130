import { useTranslation } from "react-i18next";
import RVOPartnerUpWithUsTemplate from "../04-templates/RVOTargetGroupTemplate/RVOTargetGroupTemplate";

export const FuerAufsichtsraetePage = (): JSX.Element => {
  const { t } = useTranslation();
  const pageInformation = {
    title: t("fuerAufsichtsraete.title"),
    pretitle: t("fuerAufsichtsraete.pretitle"),
    subtitle: t("fuerAufsichtsraete.subtitle"),
  };
  const benefitsSection = {
    pretitle: t("fuerAufsichtsraete.benefits.pretitle"),
    title: t("fuerAufsichtsraete.benefits.title"),
    benefits: [
      {
        title: t("fuerAufsichtsraete.benefits.benefit1.title"),
        description: t("fuerAufsichtsraete.benefits.benefit1.description"),
        illustration: "/app/illustrations/task-management.svg",
      },
      {
        title: t("fuerAufsichtsraete.benefits.benefit2.title"),
        description: t("fuerAufsichtsraete.benefits.benefit2.description"),
        illustration: "/app/illustrations/online-meeting.svg",
      },
      {
        title: t("fuerAufsichtsraete.benefits.benefit3.title"),
        description: t("fuerAufsichtsraete.benefits.benefit3.description"),
        illustration: "/app/illustrations/online-file-sharing.svg",
      },
    ],
  };
  const stepsSection = {
    title: t("fuerAufsichtsraete.how.title"),
    illustration: "/app/illustrations/goal-setting.svg",
    steps: [
      {
        title: t("fuerAufsichtsraete.how.step1.title"),
        description: t("fuerAufsichtsraete.how.step1.description"),
      },
      {
        title: t("fuerAufsichtsraete.how.step2.title"),
        description: t("fuerAufsichtsraete.how.step2.description"),
      },
      {
        title: t("fuerAufsichtsraete.how.step3.title"),
        description: t("fuerAufsichtsraete.how.step3.description"),
      },
    ],
  };

  const videoSection = {
    title: t("fuerAufsichtsraete.video.title"),
    description: t("fuerAufsichtsraete.video.description"),
    schemaUploadDate: "02.08.2024",
    url: "https://www.youtube.com/watch?v=gs5JmsOebkY",
    thumbnail: "/app/thumbnails/aufsichtsrat-video.png",
  };

  const templateSection = {
    pretitle: t("fuerAufsichtsraete.templateSection.pretitle"),
    title: t("fuerAufsichtsraete.templateSection.title"),
    description: t("fuerAufsichtsraete.templateSection.description"),
    listIntro: t("fuerAufsichtsraete.templateSection.listIntro"),
    templates: [
      {
        name: "Bestellung eines neuen Vorstandsmitglieds (optional mit Regelungen zur Vertretungsbefugnis, Amtszeit, Ressortzuweisung und Vergütung)",
      },
      {
        name: "Abberufung eines Vorstandsmitglieds und Beendigung des Anstellungsvertrags",
      },
      {
        name: "Geschäftsordnung des Aufsichtsrats (wahlweise mit Prüfungsausschuss und sonstigen Ausschüssen, für eine mitbestimmte oder nicht-mitbestimmte Aktiengesellschaft)",
      },
    ],
  };

  return (
    <RVOPartnerUpWithUsTemplate
      pageInformation={pageInformation}
      benefitsSection={benefitsSection}
      stepsSection={stepsSection}
      videoSection={videoSection}
      templateSection={templateSection}
    />
  );
};

export default FuerAufsichtsraetePage;
